import React from "react";
import whatsapp from '../assets/images/whatsapp-2.webp';
import { btnWhatsapp } from './whatsapp-btn.module.css';

export default function Whatsapp() {
    return (
        // <a className={btnWhatsapp} href="http://wa.me/5219994457716" target="_blank" rel="noopener noreferrer">
        <a className={btnWhatsapp} href="http://wa.me/529993229085" target="_blank" rel="noopener noreferrer">
            <img src={whatsapp} alt="botón whatsapp" />
        </a>
    );
}