import React from 'react';
import Navbar from './navbar';
import Footer from './footer';
import WhatsappBtn from './whatsapp-btn';
import './styles.css';

export default function Layout({ children }) {
    return (
        <>
            <Navbar />
            <WhatsappBtn />
            <main role="main">
                {children}
            </main>
            <Footer />
        </>
    );
}