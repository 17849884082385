import React, { useState , useEffect} from 'react';
import logo from '../assets/images/logoWhite.webp';
import { Link } from 'gatsby';
import { active, homeLink, menu, navLinks, scrolled } from './navbar.module.css';

export default function Navbar() {
    const [showMenu, setShowMenu] = useState(false);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset <= 100 ? window.pageYOffset : 100);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    function handleClick() {
        setShowMenu((prev) => !prev);
    }

    return (
        // <header className={showMenu || offSet ? active : null} style={{ backgroundColor: `rgba(5,30,64, ${offset}%`}}>
        <header className={`${showMenu ? active : null} ${offset == 100 ? scrolled : null}`}>
            <button className={menu} data-menu onClick={handleClick}></button>
            <Link to="/" className={ homeLink }>
                <img src={ logo } alt="logo" />
            </Link>
            <nav className={navLinks}>
                <Link to="/">Inicio</Link>
                <Link to="#servicios">Servicios</Link>
                <Link to="#contactanos">Contáctanos</Link>
            </nav>
        </header>
    );
}