import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout";

export default function NotFoundPage () {
  return (
    <Layout>
      <main>
        <h1>Page not found</h1>
        <p>
          Sorry 😔, we couldn't find what you were looking for.
        </p>
        <Link to="/">Go home</Link>.
      </main>
    </Layout>
  );
}

export const Head = () => <title>Not found</title>
