import React from 'react';
import { contactInfo, contactImg, content, socialMedia,logoInFooter } from './footer.module.css';
import logoFooter from '../assets/images/logoWhite.webp';
import whatsapp from '../assets/images/whatsapp.webp';
import facebook from '../assets/images/facebook.webp';

export default function Footer() {
    return (
        <footer>
            <div className={content}>
                <div className={contactImg}>
                    <div style={{backgroundImage: `url(${logoFooter})` }} className={logoInFooter}></div>
                    <div className={socialMedia}>
                        {/* <a href="https://wa.me/5219994457716" target="_blank" rel="noopener noreferrer"> */}
                        <a href="https://wa.me/529993229085" target="_blank" rel="noopener noreferrer">
                            <img src={whatsapp} alt="whatsapp" />
                        </a>
                        <a href="https://www.facebook.com/deepdatatechnologies" target="_blank" rel="noopener noreferrer">
                            <img src={facebook} alt="facebook" />
                        </a>
                    </div>
                </div>
                <div className={contactInfo}>
                    <h4>Contacto</h4>
                    <ul className='numberFont'>
                        <li><a href="tel:+529993229085">+52 999 322 9085</a></li>
                        {/* <li><a href="tel:+52777889910" >+52 777 88 99 10</a></li> */}
                        <li><a href="mailto:contacto@deepdatat.com">contacto@deepdatat.com</a></li>
                        <li>C. 18 x 15 y 17 Cholul, Mérida</li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}